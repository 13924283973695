const initialState = {
    labPermissions:{},
    labLists: [],
    labStatus: [],
    changedLabStatus : [],
    loadingLab: false,
    errorsLab: {}
}

const  labReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_LABLIST_PENDING":
        case "ADD_LAB_PENDING":
        case "UPDATE_LAB_PENDING":
        case "UPDATE_LAB_STATUS_PENDING":
        case "DELETE_LAB_PENDING":
        case "GET_LABLISTBYID_PENDING":
            return { ...state, loadingLab: true };

        case "GET_LABLIST_FULFILLED":
            return {
                ...state,
                loadingLab: false,
                labLists: action.payload,
                labCount: action.count,
                labPermissions:action.permissions,
            };

        case "ADD_LAB_FULFILLED":
            return {
                ...state,
                loadingLab: false,
                changedLabStatus: action.payload,
            };

        case "UPDATE_LAB_FULFILLED":
            return {
                ...state,
                loadingLab: false,
                changedLabStatus: action.payload,
            };

        case "UPDATE_LAB_STATUS_FULFILLED":
            return {
                ...state,
                loadingLab: false,
                changedLabStatus: action.payload,
            };

        case "GET_LABLISTBYID_FULFILLED":
            return {
                ...state,
                loadingLab: false,
                labLists: action.payload
            };

        case "DELETE_LAB_FULFILLED":
            return {
                ...state,
                loadingLab: false,
                changedLabStatus: action.payload
            };

        case "RESET_DELETE_LABS_STATUS":
            return {
                ...state,
                loadingLab: false,
                changedLabStatus: [],
                errorsLab:{}
            };

        case "GET_LABLIST_REJECTED":
        case "ADD_LAB_REJECTED":
        case "UPDATE_LAB_REJECTED":
        case "UPDATE_LAB_STATUS_REJECTED":
        case "DELETE_LAB_REJECTED":
        case "GET_LABLISTBYID_REJECTED":
            return {
                ...state,
                loadingLab: false,
                errorsLab: action.payload
            };

        default:
            return state;
    }
}

export default labReducer;