const initialState = {
    doctorPermissions:{},
    doctorLists: [],
    doctorStatus: [],
    changedDoctorStatus : [],
    loadingDoctor: false,
    errorsDoctor: {}
}

const  doctorReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DOCTORLIST_PENDING":
        case "ADD_DOCTOR_PENDING":
        case "UPDATE_DOCTOR_PENDING":
        case "UPDATE_DOCTOR_STATUS_PENDING":
        case "DELETE_DOCTOR_PENDING":
        case "GET_DOCTORLISTBYID_PENDING":
            return { ...state, loadingDoctor: true };

        case "GET_DOCTORLIST_FULFILLED":
            return {
                ...state,
                loadingDoctor: false,
                doctorLists: action.payload,
                doctorCount: action.count,
                doctorPermissions:action.permissions,
            };

        case "ADD_DOCTOR_FULFILLED":
            return {
                ...state,
                loadingDoctor: false,
                changedDoctorStatus: action.payload,
            };

        case "UPDATE_DOCTOR_FULFILLED":
            return {
                ...state,
                loadingDoctor: false,
                changedDoctorStatus: action.payload,
            };

        case "UPDATE_DOCTOR_STATUS_FULFILLED":
            return {
                ...state,
                loadingDoctor: false,
                changedDoctorStatus: action.payload,
            };

        case "GET_DOCTORLISTBYID_FULFILLED":
            return {
                ...state,
                loadingDoctor: false,
                doctorLists: action.payload
            };

        case "DELETE_DOCTOR_FULFILLED":
            return {
                ...state,
                loadingDoctor: false,
                changedDoctorStatus: action.payload
            };

        case "RESET_DELETE_DOCTORS_STATUS":
            return {
                ...state,
                loadingDoctor: false,
                changedDoctorStatus: [],
                errorsDoctor:{}
            };

        case "GET_DOCTORLIST_REJECTED":
        case "ADD_DOCTOR_REJECTED":
        case "UPDATE_DOCTOR_REJECTED":
        case "UPDATE_DOCTOR_STATUS_REJECTED":
        case "DELETE_DOCTOR_REJECTED":
        case "GET_DOCTORLISTBYID_REJECTED":
            return {
                ...state,
                loadingDoctor: false,
                errorsDoctor: action.payload
            };

        default:
            return state;
    }
}

export default doctorReducer;