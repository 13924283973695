import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLabListById, saveLabData, updateLabData } from "../../redux/actions/LabActions";
import FormInput from "../FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterInsuranceList, getMasterQualificationList } from "../../redux/actions/MasterActions";
import Preloader from "../Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddLab = ({ show, onHide, labId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [isTypeAvailable, setIsTypeAvailable] = useState(false);

  const labTypeList = [
    {
      id: "Pathology",
      name: 'Pathology'
    },
    {
      id: "Radiology",
      name: 'Radiology'
    }
  ]

  const pathologyTypeList = [
    {
      id: "Inhouse",
      name: 'Inhouse'
    },
    {
      id: "Outsource",
      name: 'Outsource'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    labReducer: {
      loadingLab,
      errorsLab,
      labLists,
      changedLabStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterInsuranceList,
      masterQualificationList,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterInsuranceList(auth.token));
      dispatch(getMasterQualificationList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(labId){
      dispatch(updateLabData(auth?.token, formData, labId));
    } else {
      dispatch(saveLabData(auth?.token, formData));
    }
  };


  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(labId) {
      dispatch(getLabListById(auth?.token, labId));
    }
  }, [auth, labId]);


  useEffect(() => {
    if(Object.keys(placeData).length > 0 && Object.keys(masterCityStateWiseLists).length>0) {
        const addressComponents = placeData?.address_components;
        const city_name = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const city_code=masterCityStateWiseLists?.filter(data => data.city_name == getAddressComponent(addressComponents, 'administrative_area_level_3'))
        setFormData({ ...formData, 'city_code': city_code[0]?.city_code });
    }
  },[masterCityStateWiseLists])


  useEffect(() => {
    if(labId && labLists && Object.keys(labLists).length > 0) {
      setFormData({
        lab_name: labLists?.lab_name,
        lat: labLists?.lat,
        lng: labLists?.lng,
        address: labLists?.address,
        email: labLists?.email || "",
        mobile_number: labLists?.mobile_number,
        tpa_name: labLists?.tpa_name,
        tpa_number: labLists?.tpa_number,
        state_code: labLists?.lab_cities?.cities_state?.state_code,
        city_code: labLists?.lab_cities?.city_code,
      });
    }
  }, [labLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);


  const handleInputChange = async (e) => {
    const input = e.target.value;
    setFormData({ ...formData, search_name: input });

    if (input.length > 2)
    {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input,
        componentRestrictions: { country: "IN" },
        types: ["doctor"],
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          setShowSuggestions(true);
        }
      });
    }
    else
    {
      setShowSuggestions(false);
    }
  };


  const handlePlaceSelected = async (placeId) => {
    const service = new window.google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceData(place)
        const addressComponents = place?.address_components;
        const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
        const city = getAddressComponent(addressComponents, 'administrative_area_level_3');
        const state_code=masterStateLists?.filter(state => state.state_name == getAddressComponent(addressComponents, 'administrative_area_level_1'))
        setFormData({
          ...formData,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          lab_name: place.name || "",
          mobile_number: (place.formatted_phone_number || "").replace(/\s/g, '').replace(/^0/, ''),
          address: place.formatted_address || "",
          state_code:state_code[0]?.state_code,
          city_name: city || "",
        });
        setShowSuggestions(false);
      }
    });
  };


  const getAddressComponent = (components, type) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : '';
  };


  // const customSelectStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     borderColor: errorsLab?.data?.vehicle_model ? 'red' : base.borderColor,
  //     '&:hover': {
  //       borderColor: errorsLab?.data?.vehicle_model ? 'red' : base.borderColor,
  //     }
  //   }),
  // };


  useEffect(() => {
    if (changedLabStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedLabStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedLabStatus, dispatch]);


  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Lab")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsLab?.data?.message && (
          <div className="alert alert-danger">
            {errorsLab?.data?.message}
          </div>
        )}

        <Card>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Type of Lab")}</Form.Label>
                  <Select
                    options={labTypeList}
                    values={labTypeList?.filter(type => type?.id === formData?.lab_type)}
                    name="lab_type"
                    labelField={"name"}
                    valueField={"id"}
                    searchBy={"name"}
                    loading={false}
                    onChange={(selectedData) => {
                      selectHandler2(selectedData[0]?.id, "lab_type")
                      setIsTypeAvailable(selectedData[0]?.name);
                    }}
                    styles={null}
                  />
                  
                  {errorsLab?.data?.lab_type && (
                    <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsLab?.data?.lab_type}</p>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Basic Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} Lab Name`}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.treating_first_name}
                      name="treating_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsLab?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsLab?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsLab?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsLab?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsLab?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsLab?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} Address (As Per Field Visit)`}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.treating_middle_name}
                      name="treating_middle_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} Contact No.`}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.treating_last_name}
                      name="treating_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>
                  
                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} No.`}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} Email ID`}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} GPS Photo Map`}
                      type="file"
                      errors={errorsLab?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={`${formData.lab_type} Photo (As Per Field Visit)`}
                      type="file"
                      errors={errorsLab?.data}
                      value={formData.lab_name}
                      name="lab_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-1"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        {formData.lab_type} Social Media
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-1">
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Whatsapp Link")}
                              type="text"
                              errors={errorsLab?.data}
                              value={formData.hospital_whatsapp_link}
                              name="hospital_whatsapp_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Instagram Link")}
                              type="text"
                              errors={errorsLab?.data}
                              value={formData.hospital_instagram_link}
                              name="hospital_instagram_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Facebook Link")}
                              type="text"
                              errors={errorsLab?.data}
                              value={formData.hospital_facebook_link}
                              name="hospital_facebook_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Linkdin Link")}
                              type="text"
                              errors={errorsLab?.data}
                              value={formData.hospital_linkdin_link}
                              name="hospital_linkdin_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Twitter Link")}
                              type="text"
                              errors={errorsLab?.data}
                              value={formData.hospital_twitter_link}
                              name="hospital_twitter_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Justdial Link")}
                              type="text"
                              errors={errorsLab?.data}
                              value={formData.hospital_eyecon_link}
                              name="hospital_eyecon_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={1}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Lab Owner Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Owner Salutation")}</Form.Label>
                      <Select
                        options={salutationList}
                        values={salutationList?.filter(salutation => salutation?.id === formData?.owner_salutation)}
                        name="owner_salutation"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "owner_salutation")
                        }}
                        styles={null}
                      />
                      
                      {errorsLab?.data?.owner_salutation && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsLab?.data?.owner_salutation}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Owner First Name")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.tpa_first_name}
                      name="tpa_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Owner Middle Name")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.tpa_middle_name}
                      name="tpa_middle_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Owner Last Name")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.tpa_last_name}
                      name="tpa_last_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Owner Mobile Number")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.tpa_number}
                      name="tpa_number"
                      onChange={selectMobileHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Owner Email ID")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.tpa_email}
                      name="tpa_email"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Owner Qualification")}</Form.Label>
                      <Select
                        options={masterQualificationList}
                        values={masterQualificationList?.filter(qual => qual?.id === formData?.hospital_owner_degree)}
                        name="hospital_owner_degree"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={false}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "hospital_owner_degree")
                        }}
                        styles={null}
                      />
                      
                      {errorsLab?.data?.hospital_owner_degree && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsLab?.data?.hospital_owner_degree}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Owner of Pathology Photo")}
                      type="file"
                      errors={errorsLab?.data}
                      value={formData.tpa_first_name}
                      name="tpa_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Shop Act")}
                      type="file"
                      errors={errorsLab?.data}
                      value={formData.tpa_first_name}
                      name="tpa_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("License")}
                      type="file"
                      errors={errorsLab?.data}
                      value={formData.tpa_first_name}
                      name="tpa_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                {formData.lab_type} Located inside the Hospital
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t(`${formData.lab_type} Located Inside The Hospital`)}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="inside_hospital"
                        value="Yes"
                        checked={formData.inside_hospital === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="inside_hospital"
                        value="No"
                        checked={formData.inside_hospital === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData.inside_hospital === "Yes" && (
                    <>
                      <Col lg={4}>
                        <FormInput
                          label={t("Hospital Name")}
                          type="text"
                          errors={errorsLab?.data}
                          value={formData.tpa_first_name}
                          name="tpa_first_name"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Rate List
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Rate Upload")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.tpa_first_name}
                      name="tpa_first_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Available Facility
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsLab?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Blacklisted
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Backlisted Status")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="backlisted_status"
                        value="Yes"
                        checked={formData.backlisted_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="backlisted_status"
                        value="No"
                        checked={formData.backlisted_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Data Upload")}
                      type="file"
                      errors={errorsLab?.data}
                      value={formData.blacklisted_insurance_data}
                      name="blacklisted_insurance_data"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Grievance Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Grievance and Escalation")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="grievance_status"
                        value="Yes"
                        checked={formData.grievance_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="grievance_status"
                        value="No"
                        checked={formData.grievance_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <br />
                {formData.grievance_status === "Yes" && (<>
                  <Row>
                    <Col lg={4}>
                      <FormInput
                        label={t("Type of Grievance and Escalation")}
                        type="text"
                        errors={errorsLab?.data}
                        value={formData.type_of_grievance}
                        name="type_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Date of Grievance and Escalation")}
                        type="text"
                        errors={errorsLab?.data}
                        value={formData.date_of_grievance}
                        name="date_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Notification of Grievance and Escalation")}
                        type="text"
                        errors={errorsLab?.data}
                        value={formData.notification_of_grievance}
                        name="notification_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Reason of the Grievance and Escalation")}
                        type="text"
                        errors={errorsLab?.data}
                        value={formData.reason_of_grievance}
                        name="reason_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Status of Grievance and Escalation")}
                        type="text"
                        errors={errorsLab?.data}
                        value={formData.status_of_grievance}
                        name="status_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Final Conclusion of Grievance and Escalation")}
                        type="text"
                        errors={errorsLab?.data}
                        value={formData.final_of_grievance}
                        name="final_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </Row>
                </>)}

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingLab && <Preloader />}
    </Modal>
  );
};

export default AddLab;
