const initialState = {
    basicSavePermissions:{},
    basicSaveLists: [],
    basicSaveStatus: [],
    changedBasicSaveStatus : [],
    loadingBasicSave: false,
    errorsBasicSave: {}
}

const  basicSaveReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BASICSAVELIST_PENDING":
        case "ADD_BASICSAVE_PENDING":
        case "UPDATE_BASICSAVE_PENDING":
        case "UPDATE_BASICSAVE_STATUS_PENDING":
        case "DELETE_BASICSAVE_PENDING":
        case "GET_BASICSAVELISTBYID_PENDING":
            return { ...state, loadingBasicSave: true };

        case "GET_BASICSAVELIST_FULFILLED":
            return {
                ...state,
                loadingBasicSave: false,
                basicSaveLists: action.payload,
                basicSaveCount: action.count,
                basicSavePermissions:action.permissions,
            };

        case "ADD_BASICSAVE_FULFILLED":
            return {
                ...state,
                loadingBasicSave: false,
                changedBasicSaveStatus: action.payload,
            };

        case "UPDATE_BASICSAVE_FULFILLED":
            return {
                ...state,
                loadingBasicSave: false,
                changedBasicSaveStatus: action.payload,
            };

        case "UPDATE_BASICSAVE_STATUS_FULFILLED":
            return {
                ...state,
                loadingBasicSave: false,
                changedBasicSaveStatus: action.payload,
            };

        case "GET_BASICSAVELISTBYID_FULFILLED":
            return {
                ...state,
                loadingBasicSave: false,
                basicSaveLists: action.payload
            };

        case "DELETE_BASICSAVE_FULFILLED":
            return {
                ...state,
                loadingBasicSave: false,
                changedBasicSaveStatus: action.payload
            };

        case "RESET_DELETE_BASICSAVES_STATUS":
            return {
                ...state,
                loadingBasicSave: false,
                changedBasicSaveStatus: [],
                errorsBasicSave:{}
            };

        case "GET_BASICSAVELIST_REJECTED":
        case "ADD_BASICSAVE_REJECTED":
        case "UPDATE_BASICSAVE_REJECTED":
        case "UPDATE_BASICSAVE_STATUS_REJECTED":
        case "DELETE_BASICSAVE_REJECTED":
        case "GET_BASICSAVELISTBYID_REJECTED":
            return {
                ...state,
                loadingBasicSave: false,
                errorsBasicSave: action.payload
            };

        default:
            return state;
    }
}

export default basicSaveReducer;