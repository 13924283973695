import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Modal} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getHospitalListById, saveHospitalData, updateHospitalData } from "../../redux/actions/HospitalActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterInsuranceList, getMasterQualificationList, getMasterDesignationList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import CustomToggle from "../../Components/CustomToggle";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddHospital = ({ show, onHide, hospitalId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [isGrievanceAvailable, setIsGrievanceAvailable] = useState(false);

  const categoryList = [
    {
      id: "Private",
      name: 'Private'
    },
    {
      id: "Semi Government",
      name: 'Semi Government'
    },
    {
      id: "Government Central",
      name: 'Government Central'
    },
    {
      id: "State",
      name: 'State'
    },
    {
      id: "Charitable",
      name: 'Charitable'
    }
  ]

  const hospitalManagementList = [
    {
      id: "Director",
      name: 'Director'
    },
    {
      id: "Secretory",
      name: 'Secretory'
    },
    {
      id: "Billing Head",
      name: 'Billing Head'
    }
  ]

  const registrationStatusList = [
    {
      id: "Valid",
      name: 'Valid'
    },
    {
      id: "Expired",
      name: 'Expired'
    }
  ]

  const countryList = [
    {
      id: "India",
      name: 'India'
    }
  ]

  const salutationList = [
    {
      id: "Mr",
      name: 'Mr'
    },
    {
      id: "Mrs",
      name: 'Mrs'
    }
  ]

  const accountTypeList = [
    {
      id: "Saving Account",
      name: 'Saving Account'
    },
    {
      id: "Current Account",
      name: 'Current Account'
    }
  ]
  

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    hospitalReducer: {
      loadingHospital,
      errorsHospital,
      hospitalLists,
      changedHospitalStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterInsuranceList,
      masterDesignationList,
      masterQualificationList,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterInsuranceList(auth.token));
      dispatch(getMasterQualificationList(auth.token));
      dispatch(getMasterDesignationList(auth.token));
    }
  }, [auth?.token]);


  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);


  const [validated, setValidated] = useState(false);
  const autocompleteRef = useRef(null);
  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(hospitalId){
      dispatch(updateHospitalData(auth?.token, formData, hospitalId));
    } else {
      dispatch(saveHospitalData(auth?.token, formData));
    }
  };


  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(hospitalId) {
      dispatch(getHospitalListById(auth?.token, hospitalId));
    }
  }, [auth, hospitalId]);


  useEffect(() => {
    if(hospitalId && hospitalLists && Object.keys(hospitalLists).length > 0) {
      setFormData({
        hospital_name: hospitalLists?.hospital_name,
        lat: hospitalLists?.lat,
        lng: hospitalLists?.lng,
        address: hospitalLists?.address,
        email: hospitalLists?.email || "",
        mobile_number: hospitalLists?.mobile_number,
        tpa_name: hospitalLists?.tpa_name,
        tpa_number: hospitalLists?.tpa_number,
        state_code: hospitalLists?.hospital_cities?.cities_state?.state_code,
        city_code: hospitalLists?.hospital_cities?.city_code,
      });
    }
  }, [hospitalLists]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };


  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };


  useEffect(() => {
    if (changedHospitalStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedHospitalStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [changedHospitalStatus, dispatch]);


  const formattedExpiryDate = formData.expiry_date ? formData.expiry_date.split('T')[0] : '';
  const formattedRenewalDate = formData.renewal_date ? formData.renewal_date.split('T')[0] : '';
  const formattedCorticateDate = formData.corticate_date ? formData.corticate_date.split('T')[0] : '';


  return (
    <Modal show={show} onHide={onHide} size="xl" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Add or Edit Hospital")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorsHospital?.data?.message && (
          <div className="alert alert-danger">
            {errorsHospital?.data?.message}
          </div>
        )}

        <Accordion id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Hospital Basic Info
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital Name")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_name}
                      name="hospital_name"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Country")}</Form.Label>
                      <Select
                        options={countryList}
                        values={countryList?.filter(country => country?.id === formData?.country_name)}
                        name="country_name"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.id, "country_name")
                        }}
                        styles={null}
                      />
                      
                      {errorsHospital?.data?.country_name && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsHospital?.data?.country_name}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("State / Union Territory")}</Form.Label>
                      <Select
                        options={masterStateLists}
                        values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                        name="state_code"
                        labelField={"state_name"}
                        valueField={"state_code"}
                        searchBy={"state_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.state_code, "state_code")
                          setStateId(selectedData[0]?.state_code);
                        }}
                        styles={null}
                      />
                      
                      {errorsHospital?.data?.state_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsHospital?.data?.state_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("District")}</Form.Label>
                      <Select
                        options={masterCityStateWiseLists}
                        values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                        name="city_code"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "city_code")
                        }}
                        styles={null}
                      />
                      
                      {errorsHospital?.data?.city_code && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsHospital?.data?.city_code}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Taluka / Sub-District")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Village / City / Town")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Flat / House Number / Building Name")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Street Name / Road Name")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Area / Locality")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Landmark")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Pincode")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_pincode}
                      name="hospital_pincode"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Latitude")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData?.hospital_lat}
                      name="hospital_lat"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Longitude")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData?.hospital_lng}
                      name="hospital_lng"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital Address (As Per Field)")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_address}
                      name="hospital_address"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital Contact No 1")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_number}
                      name="hospital_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={2} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital Contact No 2")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_number}
                      name="hospital_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital Email ID")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.hospital_email}
                      name="hospital_email"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={2} style={{marginTop: '28px'}}>
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Save")}
                    </Button>
                  </Col>

                  <Col lg={3}>
                    <label>{t("Help Desk")}</label>
                    <div style={{marginTop: '7%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Available")}
                        name="helpdesk"
                        value="Available"
                        checked={formData.helpdesk === "Available"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Not Available")}
                        name="helpdesk"
                        value="Not Available"
                        checked={formData.helpdesk === "Not Available"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-1"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        T.P.A Details
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-1">
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("T.P.A Cordinator Salutation")}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.tpa_salutation)}
                                name="tpa_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "tpa_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.tpa_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.tpa_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("T.P.A Cordinator First Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.tpa_first_name}
                              name="tpa_first_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("T.P.A Cordinator Middle Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.tpa_middle_name}
                              name="tpa_middle_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("T.P.A Cordinator Last Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.tpa_last_name}
                              name="tpa_last_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("T.P.A Cordinator Contact No")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.tpa_number}
                              name="tpa_number"
                              onChange={selectMobileHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={2} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("T.P.A Cordinator Email ID")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.tpa_email}
                              name="tpa_email"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={2} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-2"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        R.M.O Details
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-2">
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("R.M.O Salutation")}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.rmo_salutation)}
                                name="rmo_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "rmo_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.rmo_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.rmo_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("R.M.O First Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_first_name}
                              name="rmo_first_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("R.M.O Middle Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_middle_name}
                              name="rmo_middle_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("R.M.O Last Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_last_name}
                              name="rmo_last_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("R.M.O Contact No")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_number}
                              name="rmo_number"
                              onChange={selectMobileHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={2} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("R.M.O Email ID")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_email}
                              name="rmo_email"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={2} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey="0-3"
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Nursing Staff Details
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0-3">
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Nursing Staff Salutation")}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.nursing_salutation)}
                                name="nursing_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "nursing_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.nursing_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.nursing_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Nursing Staff First Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_first_name}
                              name="rmo_first_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Nursing Staff Middle Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_middle_name}
                              name="rmo_middle_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Nursing Staff Last Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.rmo_last_name}
                              name="rmo_last_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Nursing Staff Contact No")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.staff_number}
                              name="staff_number"
                              onChange={selectMobileHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Nursing Staff Email ID")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.staff_email}
                              name="staff_email"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={2} style={{marginTop: '28px'}}>
                            <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                              {t("Save")}
                            </Button>
                          </Col>

                          <Col lg={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Hospital Category")}</Form.Label>
                              <Select
                                options={categoryList}
                                values={categoryList?.filter(category => category?.id === formData?.hospital_category)}
                                name="hospital_category"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={false}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "hospital_category")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.hospital_category && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.hospital_category}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={2}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Hospital Owner Data
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Type of Data")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Owner")}
                        name="type_of_owner"
                        value="Owner"
                        checked={formData.type_of_owner === "Owner"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("Management")}
                        name="type_of_owner"
                        value="Management"
                        checked={formData.type_of_owner === "Management"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <br />
                {formData.type_of_owner && (<>
                  <Row>
                    {formData.type_of_owner === "Management" && (<>
                      <Col lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Hospital Management Details")}</Form.Label>
                          <Select
                            options={hospitalManagementList}
                            values={hospitalManagementList?.filter(detail => detail?.id === formData?.hospital_management)}
                            name="hospital_management"
                            labelField={"name"}
                            valueField={"id"}
                            searchBy={"name"}
                            loading={false}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.id, "hospital_management")
                            }}
                            styles={null}
                          />
                          
                          {errorsHospital?.data?.hospital_management && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsHospital?.data?.hospital_management}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </>)}

                    <Col lg={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>{`${formData.type_of_owner} Salutation`}</Form.Label>
                        <Select
                          options={salutationList}
                          values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                          name="issuing_salutation"
                          labelField={"name"}
                          valueField={"id"}
                          searchBy={"name"}
                          loading={loadingMaster}
                          onChange={(selectedData) => {
                            selectHandler2(selectedData[0]?.id, "issuing_salutation")
                          }}
                          styles={null}
                        />
                        
                        {errorsHospital?.data?.issuing_salutation && (
                          <div className="invalid-feedback d-block">
                            <p className="text-danger">{errorsHospital?.data?.issuing_salutation}</p>
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <FormInput
                        label={`${formData.type_of_owner} First Name`}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.hospital_owner_first}
                        name="hospital_owner_first"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={3}>
                      <FormInput
                        label={`${formData.type_of_owner} Middle Name`}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.hospital_owner_middle}
                        name="hospital_owner_middle"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={3}>
                      <FormInput
                        label={`${formData.type_of_owner} Last Name`}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.hospital_owner_last}
                        name="hospital_owner_last"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={3}>
                      <FormInput
                        label={`${formData.type_of_owner} Contact No.`}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.management_contact}
                        name="management_contact"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={3}>
                      <FormInput
                        label={`${formData.type_of_owner} Email ID`}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.management_email}
                        name="management_email"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    {formData.type_of_owner === "Owner" && (<>
                      <Col lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Hospital Owner Degree")}</Form.Label>
                          <Select
                            options={masterQualificationList}
                            values={masterQualificationList?.filter(qual => qual?.id === formData?.hospital_owner_degree)}
                            name="hospital_owner_degree"
                            labelField={"name"}
                            valueField={"id"}
                            searchBy={"name"}
                            loading={false}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.id, "hospital_owner_degree")
                            }}
                            styles={null}
                          />
                          
                          {errorsHospital?.data?.hospital_owner_degree && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsHospital?.data?.hospital_owner_degree}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={3}>
                        <label>{t("Owner Status")}</label>
                        <div style={{marginTop: '7%'}}>
                          <Form.Check 
                            type="radio"
                            label={t("Active")}
                            name="owner_status"
                            value="Active"
                            checked={formData.owner_status === "Active"}
                            onChange={inputHandler}
                            inline
                          />
                          <Form.Check 
                            type="radio"
                            label={t("In-Active")}
                            name="owner_status"
                            value="In-Active"
                            checked={formData.owner_status === "In-Active"}
                            onChange={inputHandler}
                            inline
                          />
                        </div>
                      </Col>
                    </>)}

                    {formData.type_of_owner === "Management" && (<>
                      <Col lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Management Designation")}</Form.Label>
                          <Select
                            options={masterDesignationList}
                            values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                            name="designation_id"
                            labelField={"name"}
                            valueField={"id"}
                            searchBy={"name"}
                            loading={loadingMaster}
                            onChange={(selectedData) => {
                              selectHandler2(selectedData[0]?.id, "designation_id")
                            }}
                          />
                          
                          {errorsHospital?.data?.designation_id && (
                            <div className="invalid-feedback d-block">
                              <p className="text-danger">{errorsHospital?.data?.designation_id}</p>
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={3}>
                        <FormInput
                          label={t("Photo")}
                          type="file"
                          errors={errorsHospital?.data}
                          value={formData.management_photo}
                          name="management_photo"
                          onChange={inputHandler}
                          containerClass={"mb-3"}
                        />
                      </Col>
                    </>)}
                  </Row>

                  {formData.type_of_owner === "Owner" && (<>
                    <Accordion>
                      <Card className="mt-3">
                        <Card.Header>
                          <CustomToggle
                            eventKey={2-1}
                            containerClass="m-0"
                            linkClass="text-dark"
                          >
                            <i className="mdi mdi-help-circle me-1 text-primary"></i>
                            Owner Social Media
                          </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={2-1}>
                          <Card.Body>
                            <Row>
                              <Col lg={4}>
                                <FormInput
                                  label={t("Owner Profesional Website")}
                                  type="text"
                                  errors={errorsHospital?.data}
                                  value={formData.rmo_first_name}
                                  name="rmo_first_name"
                                  onChange={inputHandler}
                                  containerClass={"mb-3"}
                                />
                              </Col>

                              <Col lg={4}>
                                <FormInput
                                  label={t("Owner Whatsapp")}
                                  type="text"
                                  errors={errorsHospital?.data}
                                  value={formData.rmo_middle_name}
                                  name="rmo_middle_name"
                                  onChange={inputHandler}
                                  containerClass={"mb-3"}
                                />
                              </Col>

                              <Col lg={4}>
                                <FormInput
                                  label={t("Owner Instagram")}
                                  type="text"
                                  errors={errorsHospital?.data}
                                  value={formData.rmo_last_name}
                                  name="rmo_last_name"
                                  onChange={inputHandler}
                                  containerClass={"mb-3"}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </>)}

                  <div className="d-flex justify-content-center">
                    {loading ? 
                      <Button color="primary" disabled>
                        <Spinner animation="border" size="sm" />
                        {t("Please wait ...")}
                      </Button>
                    :
                      <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                        {t("Submit")}
                      </Button>
                    }
                  </div>
                </>)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={3}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Hospital Account Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={3}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital GST Number")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.gst_number}
                      name="gst_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Hospital PAN Number")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData?.pan_number}
                      name="pan_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey={3-1}
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Hospital Bank Details
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={3-1}>
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <FormInput
                              label={t("Account Holder Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.account_holder_name}
                              name="account_holder_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Bank Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.bank_account_name}
                              name="bank_account_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Bank Account Number")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.bank_account_number}
                              name="bank_account_number"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("IFSC Code")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.ifsc_code}
                              name="ifsc_code"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Branch Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.branch_name}
                              name="branch_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Account Type")}</Form.Label>
                              <Select
                                options={accountTypeList}
                                values={accountTypeList?.filter(account => account?.id === formData?.account_type)}
                                name="account_type"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={false}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "account_type")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.account_type && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.account_type}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={3}>
                            <FormInput
                              label={t("Bank Address")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.bank_address}
                              name="bank_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={4}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Registration Details Form C
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={4}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Registration Number")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.gst_number}
                      name="gst_number"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <label>{t("Registration Status")}</label>
                    <div style={{ marginTop: '5%' }}>
                      <Form.Check 
                        type="radio"
                        label={t("Valid")}
                        name="registration_status"
                        value="Valid"
                        checked={formData.registration_status === "Valid"}
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        inline
                      />
                      <Form.Check
                        type="radio"
                        label={t("Expired")}
                        name="registration_status"
                        value="Expired"
                        checked={formData.registration_status === "Expired"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData.registration_status === "Valid" && (<>
                    <Col lg={4}>
                      <FormInput
                        label={t("Registration Date")}
                        type="date"
                        errors={errorsHospital?.data}
                        value={formattedRenewalDate}
                        name="renewal_date"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Valid Upto Date")}
                        type="date"
                        errors={errorsHospital?.data}
                        value={formattedExpiryDate}
                        name="expiry_date"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </>)}

                  {formData.registration_status === "Expired" && (<>
                    <Col lg={4}>
                      <FormInput
                        label={t("Expired Date")}
                        type="date"
                        errors={errorsHospital?.data}
                        value={formattedExpiryDate}
                        name="renewal_date"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Renewal Date")}
                        type="date"
                        errors={errorsHospital?.data}
                        value={formattedRenewalDate}
                        name="expiry_date"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </>)}

                  <Col lg={4}>
                    <FormInput
                      label={t("Corticate Issuing Date")}
                      type="date"
                      errors={errorsHospital?.data}
                      value={formattedCorticateDate}
                      name="corticate_date"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label>{t("Issuing Office Authority")}</Form.Label>
                      <button 
                        className="btn btn-link p-0 text-decoration-none" 
                        onClick={() => window.open('https://rtionline.gov.in/', '_blank')}
                        type="button"
                      >
                        {t("Online RTI ")}
                      </button>
                    </div>
                    <FormInput
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.issuing_authority}
                      name="issuing_authority"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey={4-1}
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Issuing Authority Officer Details
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={4-1}>
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Issuing Officer Salutation")}</Form.Label>
                              <Select
                                options={salutationList}
                                values={salutationList?.filter(salutation => salutation?.id === formData?.issuing_salutation)}
                                name="issuing_salutation"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "issuing_salutation")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.issuing_salutation && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.issuing_salutation}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Issuing Officer First Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.issuing_first_name}
                              name="issuing_first_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Issuing Officer Middle Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.issuing_middle_name}
                              name="issuing_middle_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Issuing Officer Last Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.issuing_last_name}
                              name="issuing_last_name"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Issuing Officer Contact Number")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData?.issuing_officer_number}
                              name="issuing_officer_number"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Issuing Officer Email ID")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.issuing_officer_email}
                              name="issuing_officer_email"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Country")}</Form.Label>
                              <Select
                                options={countryList}
                                values={countryList?.filter(country => country?.id === formData?.country_name)}
                                name="country_name"
                                labelField={"name"}
                                valueField={"id"}
                                searchBy={"name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.id, "country_name")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.country_name && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.country_name}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("State / Union Territory")}</Form.Label>
                              <Select
                                options={masterStateLists}
                                values={masterStateLists?.filter(state => state?.state_code === formData?.state_code)}
                                name="state_code"
                                labelField={"state_name"}
                                valueField={"state_code"}
                                searchBy={"state_name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.state_code, "state_code")
                                  setStateId(selectedData[0]?.state_code);
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.state_code && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.state_code}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("District")}</Form.Label>
                              <Select
                                options={masterCityStateWiseLists}
                                values={masterCityStateWiseLists?.filter(city => city?.city_code === formData?.city_code)}
                                name="city_code"
                                labelField={"city_name"}
                                valueField={"city_code"}
                                searchBy={"city_name"}
                                loading={loadingMaster}
                                onChange={(selectedData) => {
                                  selectHandler2(selectedData[0]?.city_code, "city_code")
                                }}
                                styles={null}
                              />
                              
                              {errorsHospital?.data?.city_code && (
                                <div className="invalid-feedback d-block">
                                  <p className="text-danger">{errorsHospital?.data?.city_code}</p>
                                </div>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Taluka / Sub-District")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Village / City / Town")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Flat / House Number / Building Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Street Name / Road Name")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Area / Locality")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Landmark")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_address}
                              name="hospital_address"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Pincode")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_pincode}
                              name="hospital_pincode"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={5}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Cashless Status Insurance Company Name
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={5}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Cashless Facility Available")}</label>
                    <div style={{ marginTop: '5%' }}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="facility_available"
                        value="Yes"
                        checked={formData.facility_available === "Yes"}
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        inline
                      />
                      <Form.Check
                        type="radio"
                        label={t("No")}
                        name="facility_available"
                        value="No"
                        checked={formData.facility_available === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  {formData.facility_available === "Yes" && (<>
                    <Col lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Insurance Company")}</Form.Label>
                        <Select
                          options={masterInsuranceList}
                          values={masterInsuranceList?.filter(insurance => insurance?.id === formData?.insurance_company)}
                          name="insurance_company"
                          labelField={"name"}
                          valueField={"id"}
                          searchBy={"name"}
                          loading={loadingMaster}
                          multi={true}
                          onChange={(selectedData) => {
                            selectHandler2(selectedData[0]?.id, "insurance_company")
                          }}
                          styles={null}
                        />
                        
                        {errorsHospital?.data?.insurance_company && (
                          <div className="invalid-feedback d-block">
                            <p className="text-danger">{errorsHospital?.data?.insurance_company}</p>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </>)}

                  <Col lg={4}>
                    <FormInput
                      label={t("Data Upload")}
                      type="file"
                      errors={errorsHospital?.data}
                      value={formData.cashless_insurance_data}
                      name="cashless_insurance_data"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={6}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Rohini Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={6}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Rohini ID")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.rohini_id}
                      name="rohini_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Rohini ID Link")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData?.rohini_id_link}
                      name="rohini_id_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Google Map Link")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.google_map_link}
                      name="google_map_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={7}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Hospital Profile
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={7}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <FormInput
                      label={t("Online Hospital Photo Link")}
                      type="file"
                      errors={errorsHospital?.data}
                      value={formData.hospital_photo_link}
                      name="hospital_photo_link"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Online Field Photo")}
                      type="file"
                      errors={errorsHospital?.data}
                      value={formData?.field_photo}
                      name="field_photo"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Professional Website")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.professional_website}
                      name="professional_website"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Staff Adequently")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.staff_adequently}
                      name="staff_adequently"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Rooms Category")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.room_category}
                      name="room_category"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("No. Of Beds (As per Form C)")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.no_of_beds}
                      name="no_of_beds"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("No. Of Beds (As per Field Visit)")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.no_of_beds}
                      name="no_of_beds"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={4}>
                    <label>{t("Operation Theatre")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="operation_theatre"
                        value="Yes"
                        checked={formData.operation_theatre === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="operation_theatre"
                        value="No"
                        checked={formData.operation_theatre === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <label>{t("Tariff Display")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="tariff_display"
                        value="Yes"
                        checked={formData.tariff_display === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="tariff_display"
                        value="No"
                        checked={formData.tariff_display === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Overall Infrastructure")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.overall_infrastructure}
                      name="overall_infrastructure"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <Accordion>
                  <Card className="mt-3">
                    <Card.Header>
                      <CustomToggle
                        eventKey={7-1}
                        containerClass="m-0"
                        linkClass="text-dark"
                      >
                        <i className="mdi mdi-help-circle me-1 text-primary"></i>
                        Hospital Social Media
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={7-1}>
                      <Card.Body>
                        <Row>
                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Whatsapp Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_whatsapp_link}
                              name="hospital_whatsapp_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Instagram Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_instagram_link}
                              name="hospital_instagram_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Facebook Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_facebook_link}
                              name="hospital_facebook_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Linkdin Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_linkdin_link}
                              name="hospital_linkdin_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Twitter Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_twitter_link}
                              name="hospital_twitter_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Eyecon Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_eyecon_link}
                              name="hospital_eyecon_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>

                          <Col lg={4}>
                            <FormInput
                              label={t("Hospital Truecaller Link")}
                              type="text"
                              errors={errorsHospital?.data}
                              value={formData.hospital_truecaller_link}
                              name="hospital_truecaller_link"
                              onChange={inputHandler}
                              containerClass={"mb-3"}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={8}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Caution Hospital
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={8}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <label>{t("Backlisted Status")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="backlisted_status"
                        value="Yes"
                        checked={formData.backlisted_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="backlisted_status"
                        value="No"
                        checked={formData.backlisted_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <FormInput
                      label={t("Data Upload")}
                      type="file"
                      errors={errorsHospital?.data}
                      value={formData.blacklisted_insurance_data}
                      name="blacklisted_insurance_data"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={9}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Hospital Status
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={9}>
              <Card.Body>
                

                
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={10}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Alert
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={10}>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator Username")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.alert_creator_username}
                      name="alert_creator_username"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Alert Creator ID")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.alert_creator_id}
                      name="alert_creator_id"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Date & Time of Alert")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.date_time_alert}
                      name="date_time_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Comment on Alert")}
                      type="text"
                      errors={errorsHospital?.data}
                      value={formData.comment_alert}
                      name="comment_alert"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey={11}
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Grievance Management
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={11}>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <label>{t("Grievance and Escalation")}</label>
                    <div style={{marginTop: '5%'}}>
                      <Form.Check 
                        type="radio"
                        label={t("Yes")}
                        name="grievance_status"
                        value="Yes"
                        checked={formData.grievance_status === "Yes"}
                        onChange={inputHandler}
                        inline
                      />
                      <Form.Check 
                        type="radio"
                        label={t("No")}
                        name="grievance_status"
                        value="No"
                        checked={formData.grievance_status === "No"}
                        onChange={inputHandler}
                        inline
                      />
                    </div>
                  </Col>
                </Row>

                <br />
                {formData.grievance_status === "Yes" && (<>
                  <Row>
                    <Col lg={4}>
                      <FormInput
                        label={t("Type of Grievance and Escalation")}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.type_of_grievance}
                        name="type_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Date of Grievance and Escalation")}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.date_of_grievance}
                        name="date_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Notification of Grievance and Escalation")}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.notification_of_grievance}
                        name="notification_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Reason of the Grievance and Escalation")}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.reason_of_grievance}
                        name="reason_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Status of Grievance and Escalation")}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.status_of_grievance}
                        name="status_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>

                    <Col lg={4}>
                      <FormInput
                        label={t("Final Conclusion of Grievance and Escalation")}
                        type="text"
                        errors={errorsHospital?.data}
                        value={formData.final_of_grievance}
                        name="final_of_grievance"
                        onChange={inputHandler}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </Row>
                </>)}
                
                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner animation="border" size="sm" />
                      {t("Please wait ...")}
                    </Button>
                  :
                    <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
                      {t("Submit")}
                    </Button>
                  }
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
      {loadingHospital && <Preloader />}
    </Modal>
  );
};

export default AddHospital;
console.log("Component rendered successfully!");