import axios from "axios";

export const getBasicSaveList =
  (token, limit = 10, page = 1, search = "", sort = "desc",filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_BASICSAVELIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Basic-Save`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            filters:filters,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_BASICSAVELIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_BASICSAVELIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveBasicSaveData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_BASICSAVE_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-Basic-Save`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_BASICSAVE_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_BASICSAVE_REJECTED", payload: error.response });
    }
  };
  

  export const getBasicSaveListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_BASICSAVELISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Basic-Save/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_BASICSAVELISTBYID_FULFILLED",
        payload: response.data?.data,
        vehicleCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_BASICSAVELISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateBasicSaveData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_BASICSAVE_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Basic-Save/${id}`, // Endpoint URL with BASICSAVE ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_BASICSAVE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_BASICSAVE_REJECTED", payload: error.response });
  }
};

export const updateBasicSaveStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_BASICSAVE_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Basic-Save-Status/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_BASICSAVE_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_BASICSAVE_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteBasicSaveData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_BASICSAVE_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Basic-Save/${id}`, // Endpoint URL with BASICSAVE ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_BASICSAVE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_BASICSAVE_REJECTED", payload: error.response });
  }
};