import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import { logout } from "../../redux/actions/AuthActions";
import FormInput from "../../Components/FormInput";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import FilterModal from "../../Components/FilterModal";
import { getCaseDetailsListById } from "../../redux/actions/CaseDetailsActions";
import { getAllFoList, getAllCheckList, getAllSubCheckList } from "../../redux/actions/MasterActions";

const CaseSummaryIndex = ({ case_url , title }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    caseDetailsReducer: {
      caseDetailsPermissions,
      caseDetailsLists,
      loadingCaseDetails,
      errorsCaseDetails,
      caseDetailsCount,
      changedCaseDetailsStatus,
    },
    masterReducer: {
      masterFoList,
      masterCheckList,
      masterSubCheckList,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  useEffect(() => {
    if(id)
    {
      dispatch(getCaseDetailsListById(auth?.token, id));
    }
  }, [auth, id]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false)
    {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    // if(id)
    // {
    //   dispatch(saveCaseGeneratedData(auth?.token, formData, id));
    // }
    // else
    // {
    //   dispatch(saveCaseGeneratedData(auth?.token, formData, id));
    // }
  };

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errorsCaseDetails?.data?.vehicle_model ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errorsCaseDetails?.data?.vehicle_model ? 'red' : base.borderColor,
      }
    }),
  };

  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? caseDetailsCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };

  const [filters, setFilters] = useState({});


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: title, path: case_url, active: true },
        ]}
        title={title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Primary City")}</Form.Label>
                      <Select
                        options={[]}
                        values={[]}
                        name="primary_city"
                        labelField={"city_name"}
                        valueField={"city_code"}
                        searchBy={"city_name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          selectHandler2(selectedData[0]?.city_code, "primary_city")
                        }}
                        styles={customSelectStyles}
                      />
                      
                      {errorsCaseDetails?.data?.primary_city && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsCaseDetails?.data?.primary_city}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={3}>
                    <FormInput
                      label={t("Remark")}
                      type="text"
                      errors={errorsCaseDetails?.data}
                      value={formData.primary_name}
                      name="remark"
                      onChange={inputHandler}
                      containerClass={"mb-3"}
                    />
                  </Col>

                  <Col lg={3} style={{ margin: '-8px' }}>
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      className="mt-4"
                    >
                      {t("Submit")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div>
                <Table className="mb-0 table-sm" responsive="sm" striped bordered hover >
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Claim Number</th>
                      <th>Name</th>
                      <th>Remark</th>
                      <th>File Type</th>
                      <th>File Name</th>
                      <th>Created By</th>
                      <th>Registered At<br /></th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {caseDetailsLists.length === 0 ? (
                      <tr>
                        <td colSpan="9">No data found</td>
                      </tr>
                    ) : (
                      <>
                        {caseDetailsLists?.map?.((item, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                              <td>{item?.claim_number}</td>
                              <td>{item?.policy_number}</td>
                              <td>{item?.hospitaldCaseDetails?.hospital_name}</td>
                              <td>{item?.hospitaldCaseDetails?.hospital_cities?.city_name}</td>
                              <td>{item?.hospitaldCaseDetails?.hospital_cities?.cities_state?.state_name}</td>
                              <td>{item?.insuranceIdCaseDetails?.name}</td>
                              <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                              {(caseDetailsPermissions.edit || caseDetailsPermissions.delete) && (<>
                                <td>
                                  
                                </td>
                              </>)}
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingCaseDetails && (<Preloader/>)}
    </>
  );
};

export default CaseSummaryIndex;
