import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveCaseInitiatedData } from "../../redux/actions/CaseDetailsActions";
import { getMasterInsuranceList, getAllInsuranceManagerWiseList, getMasterHospitalList, getMasterClaimTypeList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills'
import Preloader from "../../Components/Preloader";


const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [insuranceId, setInsuranceId] = useState(null);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    caseDetailsReducer: {
      loadingCaseDetails,
      errorsCaseDetails,
      changedCaseDetailsStatus,
    },
    masterReducer: {
      masterInsuranceList,
      masterInsuranceManagerWiseList,
      masterHospitalList,
      masterClaimTypeList,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() =>{
  },[masterInsuranceManagerWiseList])

  useEffect(() => {
    if (auth?.token) {
      dispatch(getMasterInsuranceList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token && insuranceId) {
      dispatch(getAllInsuranceManagerWiseList(auth.token, insuranceId));
    }
  }, [auth?.token, insuranceId, dispatch]);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getMasterHospitalList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getMasterClaimTypeList(auth.token));
    }
  }, [auth?.token]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(saveCaseInitiatedData(auth?.token, formData));
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0)
    {
      if (errors?.status === 401)
      {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsCaseDetails && Object.keys(errorsCaseDetails).length > 0)
    {
      if (errorsCaseDetails?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsCaseDetails?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
      }
      else if (errorsCaseDetails?.status === 400)
      {

      }
      else
      {
        dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
      }
    }
  }, [errorsCaseDetails, dispatch]);

  useEffect(() => {
    if (changedCaseDetailsStatus?.success)
    {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedCaseDetailsStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
      navigate(-1);
    }
  }, [changedCaseDetailsStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };

  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,15}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errorsCaseDetails?.data?.vehicle_model ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errorsCaseDetails?.data?.vehicle_model ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Case Initiated"), path: "Initiated" },
          { label: t("Add Case Initiated"), path: "/Add-Case-Initiated", active: true },
        ]}
        title={t("Add Case Initiated")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {errors?.data?.message && (
                <div className="alert alert-danger">
                  {errors.data.message}
                </div>
              )}
              <Row>
                <Col lg={3}>
                  <FormInput
                    label={t("Claim Number")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.claim_number}
                    name="claim_number"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Policy Number")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.policy_number}
                    name="policy_number"
                    onChange={selectMobileHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Insurance Company")}</Form.Label>
                    <Select
                      options={masterInsuranceList}
                      values={masterInsuranceList?.filter(insurance => insurance.id === formData.insurance_id)}
                      name="insurance_id"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0].id, "insurance_id")
                        setInsuranceId(selectedData[0].id);
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.insurance_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.insurance_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Manager")}</Form.Label>
                    <Select
                      options={masterInsuranceManagerWiseList}
                      values={masterInsuranceManagerWiseList?.filter(manager => manager.id === formData.manager_id)}
                      name="manager_id"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0].id, "manager_id")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.manager_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.manager_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Hospital Name")}</Form.Label>
                    <Select
                      options={masterHospitalList}
                      values={masterHospitalList?.filter(hospital => hospital.id === formData.hospital_id)}
                      name="hospital_id"
                      labelField={"hospital_name"}
                      valueField={"id"}
                      searchBy={"hospital_name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0].id, "hospital_id")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.hospital_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.hospital_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Claim Type")}</Form.Label>
                    <Select
                      options={masterClaimTypeList}
                      values={masterClaimTypeList?.filter(claim => claim.id === formData.claim_type)}
                      name="claim_type"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0].id, "claim_type")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.claim_type && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.claim_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Case Open Date")}
                    type="date"
                    errors={errorsCaseDetails?.data}
                    value={formData.case_open_date}
                    name="case_open_date"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Case Open Time")}
                    type="time"
                    errors={errorsCaseDetails?.data}
                    value={formData.case_open_time}
                    name="case_open_time"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Case Close Date")}
                    type="date"
                    errors={errorsCaseDetails?.data}
                    value={formData.case_close_date}
                    name="case_close_date"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Case Close Time")}
                    type="time"
                    errors={errorsCaseDetails?.data}
                    value={formData.case_close_time}
                    name="case_close_time"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormInput
                    label={t("First Trigger")}
                    type="textarea"
                    errors={errorsCaseDetails?.data}
                    value={formData.first_trigger}
                    name="first_trigger"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    rows={6}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Diagnosis")}
                    type="textarea"
                    errors={errorsCaseDetails?.data}
                    value={formData.diagnosis}
                    name="diagnosis"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    rows={6}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                {loadingCaseDetails ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loadingCaseDetails}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMaster && (<Preloader/>)}
      {loadingCaseDetails && (<Preloader/>)}
    </>
  );
};

export default Dashboard;