import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deletePatientDataData, getPatientDataList, updatePatientDataStatusData } from "../../redux/actions/PatientDataActions";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";
import AddPatientData from "../../Components/FormComponent/AddPatientData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import FilterModal from "../../Components/FilterModal";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [editPatientDataId, setEditPatientDataId] = useState(null);
  const {
    AuthReducer: { auth,errors,loading },
    patientDataReducer: {
      patientDataPermissions,
      patientDataLists,
      loadingPatientData,
      errorsPatientData,
      patientDataCount,
      changedPatientDataStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getPatientDataList(auth?.token, paginate, page, search, sort));
  //   console.log('patientDataLists',patientDataLists);
  // }, [auth, paginate, page, search, sort]);

  const hideModel = () => {
    setShowModal(false);
    setEditPatientDataId(null);
    dispatch(getPatientDataList(auth.token, paginate, page, search, sort,filters));
  };

  const fetchPatientDataList = useCallback(() => {
    if (auth?.token) {
      dispatch(getPatientDataList(auth.token, paginate, page, search, sort,filters));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchPatientDataList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? patientDataCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePatientDataData(auth?.token,id))
      }
    });
  };

  const handleStatusChangeRequest = (id, newStatus) => {
    swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updatePatientDataStatusData(auth.token, id, newStatus));
      }
    });
  };


  useEffect(() => {
    //console.log(changedPatientDataStatus);
    if(changedPatientDataStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedPatientDataStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_PATIENTDATA_STATUS' });
      dispatch(getPatientDataList(auth?.token, paginate, page, search, sort,filters));
    }
    else if(changedPatientDataStatus.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedPatientDataStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_PATIENTDATA_STATUS' });
      dispatch(getPatientDataList(auth?.token, paginate, page, search, sort,filters));
    }
  },[changedPatientDataStatus])


  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState({ show: false, key: "", type: "", value: "" });

  const handleFilterClick = (key) => {
    if (!filters[key]) {
      setFilters((prev) => ({
        ...prev,
        [key]: { type: "", value: "" },
      }));
    }
    setModal({ show: true, key, type: filters[key]?.type || "", value: filters[key]?.value || "" });
  };

  const handleModalChange = (field, value) => {
    setModal((prev) => ({ ...prev, [field]: value }));
  };

  const handleApplyFilter = () => {
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: modal.type, value: modal.value },
    }));
    setModal({ show: false, key: "", type: "", value: "" });
  };

  const handleRemoveFilter = (key) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[key];
      return updatedFilters;
    });
    setModal({ show: false, key: "", type: "", value: "" });
  };

  useEffect(() => {
    console.log(filters);
    dispatch(getPatientDataList(auth.token, paginate, page, search, sort,filters));
  },[filters])


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { patientDatael: t("Patient Data"), path: "/Patient-Data", active: true },
        ]}
        title={t("Patient Data")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    {/* <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div> */}
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        { (Object.keys(filters).length>0) && (
                          <>
                            <Button variant="warning" onClick={() => setFilters({})}>
                              <i className="mdi mdi-filter-off me-1"></i>Clear All Filter
                            </Button>
                          </>
                        )}
                        {(patientDataPermissions.add ) && (<>
                          <Button variant="success" onClick={() => {
                            setShowModal(true)
                            setEditPatientDataId(null)
                          }} >
                            <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                          </Button>
                        </>)}

                          <Button variant="success" onClick={() => {
                            setShowModal(true)
                            setEditPatientDataId(null)
                          }} >
                            <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                          </Button>
                        {(patientDataPermissions.print ) && (<>
                          <Button variant="dark">
                            <i className="mdi mdi-printer me-1"></i>Print
                          </Button>
                        </>)}
                        {(patientDataPermissions.excel ) && (<>
                          <Button variant="info">
                            <i className="mdi mdi-file-excel me-1"></i>Excel
                          </Button>
                        </>)}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-sm" responsive="sm" striped bordered hover >
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>
                        PatientData Name<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("patientData_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        Email<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("email")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        Mobile<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("mobile_number")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        T.P.A. Name<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("tpa_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        T.P.A. Number<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("tpa_number")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        State<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("state_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        City<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("city_name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>
                        Address<br />
                        {patientDataPermissions?.filter && (<>
                          <Button variant="link" onClick={() => handleFilterClick("address")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>)}
                        
                      </th>
                      <th>Status<br /></th>
                      <th>Registered At<br /></th>
                      {(patientDataPermissions.edit || patientDataPermissions.delete) && (<>
                        <th>Action</th>
                      </>)}
                    </tr>
                  </thead>
                  <tbody>
                  {patientDataLists.length === 0 ? (
                    <tr>
                      <td colSpan="12">No data found</td>
                    </tr>
                  ) : (<>
                    {patientDataLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.patientData_name}</td>
                          <td>{item?.email}</td>
                          <td>{item?.mobile_number}</td>
                          <td>{item?.tpa_name}</td>
                          <td>{item?.tpa_number}</td>
                          <td>{item?.patientData_cities?.cities_state?.state_name}</td>
                          <td>{item?.patientData_cities?.city_name}</td>
                          <td>{item?.address}</td>
                          <td>{item?.status === "Active" ? <Badge className={"bg-success"}>{item?.status}</Badge> : <><Badge className={"bg-danger"}>{item?.status}</Badge></>}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                          {(patientDataPermissions.edit || patientDataPermissions.delete) && (<>
                            <td>
                            {(patientDataPermissions.edit ) && (<>
                              {item?.status === "Active" && (
                                <>
                                  <Link to="#" className="action-icon text-success" onClick={() => handleStatusChangeRequest(item?.id , "In-Active")}>
                                    {" "}
                                    <i className="mdi mdi-eye"></i>
                                  </Link>
                                </>
                              )}
                              {item?.status === "In-Active" && (
                                <>
                                  <Link to="#" className="action-icon text-warning" onClick={() => handleStatusChangeRequest(item?.id, "Active")}>
                                    {" "}
                                    <i className="mdi mdi-eye-off"></i>
                                  </Link>
                                </>
                              )}

                              <Link to="#" onClick={() => {
                                setShowModal(true)
                                setEditPatientDataId(item?.id)
                              }} className="action-icon text-info"> 
                                <i className="mdi mdi-square-edit-outline"></i>
                              </Link>
                            </>)}
                            {(patientDataPermissions.delete) && (<>
                              <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                                {" "}
                                <i className="mdi mdi-delete"></i>
                              </Link>
                            </>)}
                            </td>
                          </>)}
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <patientDatael class="me-1">Display :</patientDatael>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(patientDataCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(patientDataCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddPatientData show={showModal} onHide={() => hideModel()} patientDataId={editPatientDataId}  />

      <FilterModal
        show={modal.show}
        onHide={() => setModal({ show: false, key: "", type: "", value: "" })}
        modalData={modal}
        onApplyFilter={handleApplyFilter}
        onRemoveFilter={handleRemoveFilter}
        onChange={handleModalChange}
      />
      {loadingPatientData && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
