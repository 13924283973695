import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge,Form } from "react-bootstrap";
import PageTitle from "../../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { logout } from "../../../redux/actions/AuthActions";
import Preloader from "../../../Components/Preloader";
import { getUserTypePermissionListById, getUserTypePermissionListByUserTypeId, updateUserTypePermissionData} from "../../../redux/actions/UserTypePermissionActions";
import { getMasterUsertypeUserWiseList, getMasterUserUserTypeWiseList } from "../../../redux/actions/MasterActions";
import Select from "react-dropdown-select";
import { getUserPermissionListByUserId, updateUserPermissionData } from "../../../redux/actions/UserPermissionActions";


const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const [userTypeId, setUserTypeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    userPermissionReducer: {
      loadingUserPermission,
      userPermissionByUserIdLists,
      errorsUserPermission,
      userPermissionLists,
      changedUserPermissionStatus,
    },
    masterReducer: {
      loadingMaster,
      masterUsertypeLists,
      masterUserLists,
    },
  } = useSelector((state) => state);
  const masterUserListData=[];
  useEffect(() => {
    dispatch(getMasterUsertypeUserWiseList(auth?.token));
  }, [auth]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
    }
  }, [errors, dispatch]);

  useEffect(() =>{
    if(userTypeId)
    {
      dispatch(getMasterUserUserTypeWiseList(auth?.token, userTypeId));
    }
  },[userTypeId])


  useEffect(() =>{
    if(userId)
    {
      dispatch(getUserPermissionListByUserId(auth?.token, userId));
    }
    console.log('userId',userId);
  },[userId])

  useEffect(() =>{
    console.log('changedUserPermissionStatus',changedUserPermissionStatus);
    dispatch(getUserPermissionListByUserId(auth?.token, userId));
  },[changedUserPermissionStatus]);

  const handleSwitchChange = (id, permissionType,parentId,menuId) => {
    // Logic to handle switch state change
    console.log(`Switch toggled for item ID: ${id} - Permission: ${permissionType} - ${parentId}`);
    dispatch(updateUserPermissionData(auth?.token, id, permissionType,parentId,menuId,userId));
  };

  useEffect(() => {
    console.log('userPermissionByUserIdLists',userPermissionByUserIdLists);
  },[userPermissionByUserIdLists])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("User Permission"), path: "/User-Permission", active: true },
        ]}
        title={t("User Permission")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("User Type")}</Form.Label>
                      <Select
                        options={masterUsertypeLists}
                        name="userTypeId"
                        labelField={"name"}
                        valueField={"id"}
                        searchBy={"name"}
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          setUserTypeId(selectedData[0]?.id);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("User")}</Form.Label>
                      <Select
                        options={masterUserLists?.map(user => ({
                          ...user,
                          label: `${user.name}`,
                        }))}
                        name="userId"
                        labelField="label"
                        valueField="id"
                        searchBy="label"
                        loading={loadingMaster}
                        onChange={(selectedData) => {
                          setUserId(selectedData[0]?.id);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                  </Col>
                </Row>
              </div>

              <div>
              {errorsUserPermission?.data?.message && (
                <div className="alert alert-danger">
                  {errorsUserPermission?.data?.message}
                </div>
              )}
              <Table className="mb-0 table-bordered" responsive size="sm" striped>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Menu Name</th>
                    <th>Sub Menu Name</th>
                    <th>View</th>
                    <th>Add</th>
                    <th>Update</th>
                    <th>Delete</th>
                    <th>Print</th>
                    <th>Filter</th>
                    <th>Excel</th>
                  </tr>
                </thead>
                  <tbody>
                    {userPermissionByUserIdLists?.map((item, index) => (
                      <>
                        {/* Main Row for Parent Menu Item */}
                        <tr key={item.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.label}</td>
                          <td>{item?.UserTypePermissionsMenuItem?.isTitle ? "" : ""}</td>
                          <td>
                            {item?.view && (<>
                              <Form.Check
                                type="switch"
                                id={`view-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.view}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id, 
                                  "view",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap?.permissions?.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id)?.map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                          </td>
                          <td>
                            {item?.add && (<>
                              <Form.Check
                                type="switch"
                                id={`add-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.add}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id, 
                                  "add",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap?.permissions?.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id).map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                            
                          </td>
                          <td>
                            {item?.edit && (<>
                              <Form.Check
                                type="switch"
                                disabled={userTypeId==1 && (item?.id==23 || item?.id==24 || item?.id==17)}
                                id={`update-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.edit}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id, 
                                  "edit",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap.permissions.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id).map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                            
                          </td>
                          <td>
                            {item?.delete && (<>
                              <Form.Check
                                type="switch"
                                id={`delete-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.delete}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id,
                                  "delete",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap.permissions.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id).map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                            
                          </td>
                          <td>
                            {item?.print && (<>
                              <Form.Check
                                type="switch"
                                id={`print-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.print}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id, 
                                  "print",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap.permissions.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id).map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                            
                          </td>
                          <td>
                            {item?.filter && (<>
                              <Form.Check
                                type="switch"
                                id={`filter-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.filter}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id, 
                                  "filter",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap.permissions.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id).map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                            
                          </td>
                          <td>
                            {item?.excel && (<>
                              <Form.Check
                                type="switch"
                                id={`excel-switch-${item?.permissions[0]?.id}`}
                                checked={item?.permissions[0]?.excel}
                                onChange={() => handleSwitchChange(
                                  item?.permissions[0]?.id, 
                                  "excel",
                                  item?.children.filter(itemFilter => itemFilter.parentId === item?.id).flatMap(itemFlatMap => itemFlatMap.permissions.map(permission => permission.id)),
                                  item?.children.length==0 ? item?.id : item?.children.filter(itemFilter => itemFilter.parentId === item?.id).map(menuId => menuId.id)
                                )}
                              />
                            </>)}
                            
                          </td>
                        </tr>

                        {/* Rows for Submenu Items permissions */}
                        {item?.children?.map((child, childIndex) => (
                          <tr key={`${child?.permissions[0]?.id}-${childIndex}`}>
                            <th scope="row">{`${index + 1}.${childIndex + 1}`}</th>
                            <td></td>
                            <td>{child?.label}</td>
                            <td>
                              {child?.view && (<>
                                <Form.Check
                                  type="switch"
                                  id={`view-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.view}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "view",null,child?.id)}
                                />
                              </>)}
                              
                            </td>
                            <td>
                              {child?.add && (<>
                                <Form.Check
                                  type="switch"
                                  id={`add-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.add}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "add",null,child?.id)}
                                />
                              </>)}
                            </td>
                            <td>
                              {child?.edit && (<>
                                <Form.Check
                                  type="switch"
                                  disabled={userTypeId==1 && (child?.id==23 || child?.id==24 || child?.id==17)}
                                  id={`update-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.edit}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "edit",null,child?.id)}
                                />
                              </>)}
                              
                            </td>
                            <td>
                              {child?.delete && (<>
                                <Form.Check
                                  type="switch"
                                  id={`delete-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.delete}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "delete",null,child?.id)}
                                />
                              </>)}
                              
                            </td>
                            <td>
                              {child?.print && (<>
                                <Form.Check
                                  type="switch"
                                  id={`print-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.print}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "print",null,child?.id)}
                                />
                              </>)}
                              
                            </td>
                            <td>
                              {child?.filter && (<>
                                <Form.Check
                                  type="switch"
                                  id={`filter-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.filter}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "filter",null,child?.id)}
                                />
                              </>)}
                            </td>
                            <td>
                              {child?.excel && (<>
                                <Form.Check
                                  type="switch"
                                  id={`excel-switch-${child?.permissions[0]?.id}`}
                                  checked={child?.permissions[0]?.excel}
                                  onChange={() => handleSwitchChange(child?.permissions[0]?.id, "excel",null,child?.id)}
                                />
                              </>)}
                              
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
              </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {loadingUserPermission && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
