const initialState = {
    patientDataPermissions:{},
    patientDataLists: [],
    patientDataStatus: [],
    changedPatientDataStatus : [],
    loadingPatientData: false,
    errorsPatientData: {}
}

const  patientDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PATIENTDATA_LIST_PENDING":
        case "ADD_PATIENTDATA_PENDING":
        case "UPDATE_PATIENTDATA_PENDING":
        case "UPDATE_PATIENTDATA_STATUS_PENDING":
        case "DELETE_PATIENTDATA_PENDING":
        case "GET_PATIENTDATA_LISTBYID_PENDING":
            return { ...state, loadingPatientData: true };

        case "GET_PATIENTDATA_LIST_FULFILLED":
            return {
                ...state,
                loadingPatientData: false,
                patientDataLists: action.payload,
                patientDataCount: action.count,
                patientDataPermissions:action.permissions,
            };

        case "ADD_PATIENTDATA_FULFILLED":
            return {
                ...state,
                loadingPatientData: false,
                changedPatientDataStatus: action.payload,
            };

        case "UPDATE_PATIENTDATA_FULFILLED":
            return {
                ...state,
                loadingPatientData: false,
                changedPatientDataStatus: action.payload,
            };

        case "UPDATE_PATIENTDATA_STATUS_FULFILLED":
            return {
                ...state,
                loadingPatientData: false,
                changedPatientDataStatus: action.payload,
            };

        case "GET_PATIENTDATA_LISTBYID_FULFILLED":
            return {
                ...state,
                loadingPatientData: false,
                patientDataLists: action.payload
            };

        case "DELETE_PATIENTDATA_FULFILLED":
            return {
                ...state,
                loadingPatientData: false,
                changedPatientDataStatus: action.payload
            };

        case "RESET_DELETE_PATIENTDATA_STATUS":
            return {
                ...state,
                loadingPatientData: false,
                changedPatientDataStatus: [],
                errorsPatientData:{}
            };

        case "GET_PATIENTDATA_LIST_REJECTED":
        case "ADD_PATIENTDATA_REJECTED":
        case "UPDATE_PATIENTDATA_REJECTED":
        case "UPDATE_PATIENTDATA_STATUS_REJECTED":
        case "DELETE_PATIENTDATA_REJECTED":
        case "GET_PATIENTDATA_LISTBYID_REJECTED":
            return {
                ...state,
                loadingPatientData: false,
                errorsPatientData: action.payload
            };

        default:
            return state;
    }
}

export default patientDataReducer;