import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveCaseGeneratedData, getCaseDetailsListById } from "../../redux/actions/CaseDetailsActions";
import { getAllStateList, getAllCityStateWiseList,
  getAllRelationshipList, getAllOccupationList, getAllLocalityList, getAllEducationalList, getAllJobList, 
  getAllRoomCategoryList, getAllIncomeDetailsList, getAllInjuryTypeList
} from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills'
import Preloader from "../../Components/Preloader";
import { size } from "lodash-es";


const Dashboard = ({ title }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [prevData, setPrevData] = useState();
  const [stateId, setStateId] = useState(null);
  const [dobDate, setDobDate] = useState(null);

  const handleDateChange = (date) => {
    setDobDate(date);
  };

  const genderList = [
    {
      id: "Male",
      name: 'Male'
    },
    {
      id: "Female",
      name: 'Female'
    },
    {
      id: "Other",
      name: 'Other'
    }
  ]
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    caseDetailsReducer: {
      caseDetailsLists,
      loadingCaseDetails,
      errorsCaseDetails,
      changedCaseDetailsStatus,
    },
    masterReducer: {
      masterStateList,
      masterCityStateWiseList,
      masterRelationshipList,
      masterOccupationList,
      masterLocalityList,
      masterEducationalList,
      masterJobList,
      masterRoomCategoryList,
      masterIncomeDetailsList,
      masterInjuryTypeList,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token)
    {
      dispatch(getAllStateList(auth.token));
      dispatch(getAllRelationshipList(auth.token));
      dispatch(getAllOccupationList(auth.token));
      dispatch(getAllLocalityList(auth.token));
      dispatch(getAllEducationalList(auth.token));
      dispatch(getAllJobList(auth.token));
      dispatch(getAllRoomCategoryList(auth.token));
      dispatch(getAllIncomeDetailsList(auth.token));
      dispatch(getAllInjuryTypeList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id)
    {
      dispatch(saveCaseGeneratedData(auth?.token, formData, id));
    }
    else
    {
      dispatch(saveCaseGeneratedData(auth?.token, formData, id));
    }
  };

  useEffect(() => {
    if (title === "Update Case Generated")
    {
      console.log("Case Update detected in title");
      
      if (auth?.token && id)
      {
        dispatch(getCaseDetailsListById(auth?.token, id));
      }
    }
  }, [title, auth?.token, id, dispatch]);

  useEffect(()=>{
    if (title === "Update Case Generated")
    {
      if(id)
      {
        if(caseDetailsLists && Object.keys(caseDetailsLists).length>0)
        {
          setFormData({
            primary_name: caseDetailsLists?.primary_name,
            primary_phone: caseDetailsLists?.primary_phone,
            primary_email: caseDetailsLists?.primary_email,
            primary_state: caseDetailsLists?.primaryCityCaseDetails?.cities_state?.state_code,
            primary_city: caseDetailsLists?.primaryCityCaseDetails?.city_code,
            primary_address: caseDetailsLists?.primary_address,
            primary_pincode: caseDetailsLists?.primary_pincode,
            patient_name: caseDetailsLists?.patient_name,
            sameAsPrimary: caseDetailsLists?.is_patient_primary_same,
            patient_phone: caseDetailsLists?.patient_phone,
            patient_email: caseDetailsLists?.patient_email,
            patient_state: caseDetailsLists?.primaryCityCaseDetails?.cities_state?.state_code,
            patient_city: caseDetailsLists?.primaryCityCaseDetails?.city_code,
            patient_address: caseDetailsLists?.patient_address,
            patient_pincode: caseDetailsLists?.patient_pincode,
            patient_gender: caseDetailsLists?.patient_gender,
            patient_dob: caseDetailsLists?.patient_dob,
            patient_relation: caseDetailsLists?.patient_relation,
            patient_occupation: caseDetailsLists?.patient_occupation,
            locality_id: caseDetailsLists?.locality_id,
            educational_id: caseDetailsLists?.educational_id,
            job_details_id: caseDetailsLists?.job_details_id,
            room_category: caseDetailsLists?.room_category,
            income_details_id: caseDetailsLists?.income_details_id,
            injury_type: caseDetailsLists?.injury_type,
            attendent_name: caseDetailsLists?.attendent_name,
            attendent_mobile: caseDetailsLists?.attendent_mobile,
            date_of_injury: caseDetailsLists?.date_of_injury,
            date_of_admission: caseDetailsLists?.date_of_admission,
            time_of_admission: caseDetailsLists?.time_of_admission,
            date_of_discharge: caseDetailsLists?.date_of_discharge,
            dr_name: caseDetailsLists?.dr_name
          });
        }
      }
    }
  },[caseDetailsLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsCaseDetails && Object.keys(errorsCaseDetails).length > 0) {
      if (errorsCaseDetails?.status === 409) {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsCaseDetails?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
      } else if (errorsCaseDetails?.status === 400) {
        // Handle other status
      } else {
        dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
      }
    }
  }, [errorsCaseDetails, dispatch]);

  useEffect(() => {
    if (changedCaseDetailsStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedCaseDetailsStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
      navigate(-1);
    }
  }, [changedCaseDetailsStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_CASE_DETAILS_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase();
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const selectUsernameHandler = (e) => {
    const { value, name } = e.target;
    if (/^[a-z0-9.]{0,30}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
        'official_mail_id': `${value}@asg.com`,
      }));
    }
  };

  const selectHandler2 = (selectedData, name) => {
    // const selectedId = selectedData[0]?.state_code;

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errorsCaseDetails?.data?.vehicle_model ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errorsCaseDetails?.data?.vehicle_model ? 'red' : base.borderColor,
      }
    }),
  };

  const customCheckboxMargin = {
    marginBottom: '20px',
    fontSize: '12pt',
    color: 'blue',
  };

  const onDateChange = (name,value) => {
    if (value)
    {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  const formattedDobDate = formData.patient_dob ? formData.patient_dob.split('T')[0] : '';
  const formattedInjuryDate = formData.date_of_injury ? formData.date_of_injury.split('T')[0] : '';
  const formattedAdmissionDate = formData.date_of_admission ? formData.date_of_admission.split('T')[0] : '';
  const formattedDischargeDate = formData.date_of_discharge ? formData.date_of_discharge.split('T')[0] : '';

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Case Summary"), path: `Case-Summary/${id}` },
          { label: title , path: "/Add-Case-Generated", active: true },
        ]}
        title={title}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {errors?.data?.message && (
                <div className="alert alert-danger">
                  {errors.data.message}
                </div>
              )}
              <Row>
                <h3 style={{ color: '#d10b0b' }}>Primary & Patient Details -: </h3>

                <Col lg={3}>
                  <FormInput
                    label={t("Primary Name")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.primary_name}
                    name="primary_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Primary Number")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.primary_phone}
                    name="primary_phone"
                    onChange={selectMobileHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Primary Email")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.primary_email}
                    name="primary_email"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Primary Address")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.primary_address}
                    name="primary_address"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Primary State")}</Form.Label>
                    <Select
                      options={masterStateList}
                      values={masterStateList?.filter(state => state?.state_code === formData?.primary_state)}
                      name="primary_state"
                      labelField={"state_name"}
                      valueField={"state_code"}
                      searchBy={"state_name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.state_code, "primary_state")
                        setStateId(selectedData[0]?.state_code);
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.primary_state && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.primary_state}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Primary City")}</Form.Label>
                    <Select
                      options={masterCityStateWiseList}
                      values={masterCityStateWiseList?.filter(city => city?.city_code === formData?.primary_city)}
                      name="primary_city"
                      labelField={"city_name"}
                      valueField={"city_code"}
                      searchBy={"city_name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.city_code, "primary_city")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.primary_city && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.primary_city}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Primary Pincode")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.primary_pincode}
                    name="primary_pincode"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Form.Check
                    type="checkbox"
                    name="sameAsPrimary"
                    id="sameAsPrimary"
                    containerClass={"mb-3"}
                    label={t("Click This If Primary Details & Patient Details Are Same")}
                    checked={formData.sameAsPrimary || false}
                    onChange={(e) => {
                      const isChecked = e.target.checked;

                      setFormData((prevData) => {
                        const updatedFormData = isChecked
                          ? {
                              ...prevData,
                              sameAsPrimary: true,
                              patient_name: prevData.primary_name || "",
                              patient_phone: prevData.primary_phone || "",
                              patient_email: prevData.primary_email || "",
                              patient_address: prevData.primary_address || "",
                              patient_state: prevData.primary_state || null,
                              patient_city: prevData.primary_city || null,
                              patient_pincode: prevData.primary_pincode || "",
                            }
                          : {
                              ...prevData,
                              sameAsPrimary: false,
                              patient_name: "",
                              patient_phone: "",
                              patient_email: "",
                              patient_address: "",
                              patient_state: null,
                              patient_city: null,
                              patient_pincode: "",
                            };

                        return updatedFormData;
                      });
                    }}
                    style={customCheckboxMargin}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3}>
                  <FormInput
                    label={t("Patient Name")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.patient_name}
                    name="patient_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    disabled={formData?.sameAsPrimary}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Patient Number")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.patient_phone}
                    name="patient_phone"
                    onChange={selectMobileHandler}
                    containerClass={"mb-3"}
                    disabled={formData?.sameAsPrimary}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Patient Email")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.patient_email}
                    name="patient_email"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    disabled={formData?.sameAsPrimary}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Gender")}</Form.Label>
                    <Select
                      options={genderList}
                      values={genderList?.filter(gender => gender?.id === formData?.patient_gender)}
                      name="patient_gender"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "patient_gender")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.patient_gender && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.patient_gender}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Date Of Birth")}</Form.Label>
                    <DatePicker
                      selected={dobDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy/MM/dd"
                      maxDate={new Date()}
                      className="form-control"
                      withPortal
                    />
                    {errorsCaseDetails?.data?.patient_dob && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.patient_dob}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Patient Relationship")}</Form.Label>
                    <Select
                      options={masterRelationshipList}
                      values={masterRelationshipList?.filter(education => education?.id === formData?.patient_relation)}
                      name="patient_relation"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "patient_relation")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.patient_relation && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.patient_relation}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Patient Occupation")}</Form.Label>
                    <Select
                      options={masterOccupationList}
                      values={masterOccupationList?.filter(job => job?.id === formData?.patient_occupation)}
                      name="patient_occupation"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "patient_occupation")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.patient_occupation && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.patient_occupation}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Patient Address")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.patient_address}
                    name="patient_address"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    disabled={formData?.sameAsPrimary}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Patient State")}</Form.Label>
                    <Select
                      options={masterStateList}
                      values={masterStateList?.filter(state => state?.state_code === formData?.patient_state)}
                      name="patient_state"
                      labelField={"state_name"}
                      valueField={"state_code"}
                      searchBy={"state_name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.state_code, "patient_state")
                        setStateId(selectedData[0]?.state_code);
                      }}
                      styles={customSelectStyles}
                      disabled={formData?.sameAsPrimary}
                    />
                    
                    {errorsCaseDetails?.data?.patient_state && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.patient_state}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Patient City")}</Form.Label>
                    <Select
                      options={masterCityStateWiseList}
                      values={masterCityStateWiseList?.filter(city => city?.city_code === formData?.patient_city)}
                      name="patient_city"
                      labelField={"city_name"}
                      valueField={"city_code"}
                      searchBy={"city_name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.city_code, "patient_city")
                      }}
                      styles={customSelectStyles}
                      disabled={formData?.sameAsPrimary}
                    />
                    
                    {errorsCaseDetails?.data?.patient_city && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.patient_city}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Patient Pincode")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.patient_pincode}
                    name="patient_pincode"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    disabled={formData?.sameAsPrimary}
                  />
                </Col>
              </Row>

              <Row>
                <h3 style={{ color: '#d10b0b' }}>Others Details -: </h3>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Locality")}</Form.Label>
                    <Select
                      options={masterLocalityList}
                      values={masterLocalityList?.filter(locality => locality?.id === formData?.locality_id)}
                      name="locality_id"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "locality_id")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.locality_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.locality_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Educational")}</Form.Label>
                    <Select
                      options={masterEducationalList}
                      values={masterEducationalList?.filter(education => education?.id === formData?.educational_id)}
                      name="educational_id"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "educational_id")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.educational_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.educational_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Job")}</Form.Label>
                    <Select
                      options={masterJobList}
                      values={masterJobList?.filter(job => job?.id === formData?.job_details_id)}
                      name="job_details_id"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "job_details_id")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.job_details_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.job_details_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Room Category")}</Form.Label>
                    <Select
                      options={masterRoomCategoryList}
                      values={masterRoomCategoryList?.filter(room => room?.id === formData?.room_category)}
                      name="room_category"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "room_category")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.room_category && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.room_category}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Income")}</Form.Label>
                    <Select
                      options={masterIncomeDetailsList}
                      values={masterIncomeDetailsList?.filter(income => income?.id === formData?.income_details_id)}
                      name="income_details_id"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "income_details_id")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.income_details_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.income_details_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Injury Type")}</Form.Label>
                    <Select
                      options={masterInjuryTypeList}
                      values={masterInjuryTypeList?.filter(injury => injury?.id === formData?.injury_type)}
                      name="injury_type"
                      labelField={"name"}
                      valueField={"id"}
                      searchBy={"name"}
                      loading={loadingMaster}
                      onChange={(selectedData) => {
                        selectHandler2(selectedData[0]?.id, "injury_type")
                      }}
                      styles={customSelectStyles}
                    />
                    
                    {errorsCaseDetails?.data?.injury_type && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsCaseDetails?.data?.injury_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Attendent Name")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.attendent_name}
                    name="attendent_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Attendent Mobile Number")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.attendent_mobile}
                    name="attendent_mobile"
                    onChange={selectMobileHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Date Of Injury")}
                    type="date"
                    errors={errorsCaseDetails?.data}
                    value={formattedInjuryDate}
                    name="date_of_injury"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Date Of Admission")}
                    type="date"
                    errors={errorsCaseDetails?.data}
                    value={formattedAdmissionDate}
                    name="date_of_admission"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Time Of Admission")}
                    type="time"
                    errors={errorsCaseDetails?.data}
                    value={formData.time_of_admission}
                    name="time_of_admission"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Date Of Discharge")}
                    type="date"
                    errors={errorsCaseDetails?.data}
                    value={formattedDischargeDate}
                    name="date_of_discharge"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Doctor Name")}
                    type="text"
                    errors={errorsCaseDetails?.data}
                    value={formData.dr_name}
                    name="dr_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                {loadingCaseDetails ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loadingCaseDetails}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMaster && (<Preloader/>)}
      {loadingCaseDetails && (<Preloader/>)}
    </>
  );
};

export default Dashboard;