import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import bootbox from "bootbox";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import FilterModal from "../../Components/FilterModal";
import { getCaseDetailsListById } from "../../redux/actions/CaseDetailsActions";

const CaseSummaryIndex = ({ case_url , title }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    caseDetailsReducer: {
      caseDetailsPermissions,
      caseDetailsLists,
      loadingCaseDetails,
      errorsCaseDetails,
      caseDetailsCount,
      changedCaseDetailsStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  useEffect(() => {
    if(id)
    {
      dispatch(getCaseDetailsListById(auth?.token, id));
    }
  }, [auth, id]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status === 401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])

  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? caseDetailsCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };

  const openCaseActivityModal = () => {
    bootbox.dialog({
      title: "Case Activity",
      message: `
        <p>Here you can display any relevant case activity details.</p>
        <ul>
          <li>Activity 1</li>
          <li>Activity 2</li>
          <li>Activity 3</li>
        </ul>
      `,
      buttons: {
        close: {
          label: "Close",
          className: "btn-danger",
        },
      },
    });
  };

  const [filters, setFilters] = useState({});
  

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: title, path: case_url, active: true },
        ]}
        title={title}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        <Button variant="outline-info" onClick={openCaseActivityModal}>
                          Case Activity
                        </Button>

                        <Link to={`/Add-Case-Document/${id}`} target="_blank" rel="noopener noreferrer">
                          <Button variant="outline-success">
                            Upload Document
                          </Button>
                        </Link>

                        {caseDetailsLists?.case_status === 'Generated' && (
                          <Link to={`/Update-Case-Generated/${id}`} target="_blank" rel="noopener noreferrer">
                            <Button variant="outline-danger">
                              Edit Case
                            </Button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-sm" responsive="sm" striped bordered hover >
                  <thead>
                    <tr>
                      <th colSpan="2">
                        Company Name
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.insuranceIdCaseDetails?.name || 'N/A'}
                      </td>

                      <th colSpan="2">
                        Company Manager
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.ManagerIdCaseDetails?.name || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        Claim Number
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.claim_number || 'N/A'}
                      </td>

                      <th colSpan="2">
                        Policy Number
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.policy_number || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        Hospital
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.hospitaldCaseDetails?.hospital_name || 'N/A'}
                      </td>

                      <th colSpan="2">
                        Hospital Location
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.hospitaldCaseDetails?.hospital_cities?.city_name || 'N/A'} , {caseDetailsLists?.hospitaldCaseDetails?.hospital_cities?.cities_state?.state_name || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        Case Type
                      </th>
                      <td colSpan="10">
                        {caseDetailsLists?.claimTypeCaseDetails?.name || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        Case Open Date
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.case_open_date || 'N/A'}
                      </td>

                      <th colSpan="2">
                        Case Open Time
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.case_open_time || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        Case Close Date
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.case_close_date || 'N/A'}
                      </td>

                      <th colSpan="2">
                        Case Close Time
                      </th>
                      <td colSpan="4">
                        {caseDetailsLists?.case_close_time || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        Triggers
                      </th>
                      <td colSpan="10">
                        {caseDetailsLists?.first_trigger || 'N/A'}
                      </td>
                    </tr>

                    {caseDetailsLists?.case_status === 'Generated' && (
                      <>
                        <tr>
                          <th colSpan="2">
                            Insured Name
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.primary_name || 'N/A'}
                          </td>

                          <th colSpan="2">
                            Insured Number
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.primary_phone || 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="2">
                            Insured Location
                          </th>
                          <td colSpan="10">
                            {caseDetailsLists?.primary_address || 'N/A'} , 
                            {caseDetailsLists?.primaryCityCaseDetails?.city_name || 'N/A'} , 
                            {caseDetailsLists?.primaryCityCaseDetails?.cities_state?.state_name || 'N/A'} , 
                            {caseDetailsLists?.primary_pincode || 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="2">
                            Locality
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.localityCaseDetails?.name || 'N/A'}
                          </td>

                          <th colSpan="2">
                            Education
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.educationCaseDetails?.name || 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="2">
                            Job
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.jobCaseDetails?.name || 'N/A'}
                          </td>

                          <th colSpan="2">
                            Rooms
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.roomTypeCaseDetails?.name || 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="2">
                            Income
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.incomeCaseDetails?.name || 'N/A'}
                          </td>

                          <th colSpan="2">
                            Injury Type
                          </th>
                          <td colSpan="4">
                            {caseDetailsLists?.InjuryTypeCaseDetails?.name || 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="2">
                            Generated Case By
                          </th>
                          <td colSpan="10">
                            {caseDetailsLists?.generatedByCaseDetails?.name || 'N/A'}
                          </td>
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody>
                    
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          {caseDetailsLists?.case_status === 'Initiated' && (
            <Link to={`/Add-Case-Generated/${id}`}>
              <Button variant="outline-danger">
                Generated Case
              </Button>
            </Link>
          )}

          {caseDetailsLists?.case_status === 'Generated' && (
            <Link to={`/Investigator-Allocation/${id}`}>
              <Button variant="outline-danger">
                Investigator Allocation
              </Button>
            </Link>
          )}
        </Col>
      </Row>
      {loadingCaseDetails && (<Preloader/>)}
    </>
  );
};

export default CaseSummaryIndex;
